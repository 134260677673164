<main class="main-content theme-bo" *ngIf="location">

  <section class="section section-school-header">
    <div class="st-inner py-0">
        <lottie-player
                src="/assets/img/svg/school/bo/1.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>
      <span class="after"></span>

        <div class="scroll-container" (window:scroll)="onScroll($event)">
            <ng-lottie [options]="lottieOptions"></ng-lottie>
        </div>

      <a href="#" (click)="goBack($event)" class="page-btn-back">
        <span class="label">Terug</span>
        <i class="arrow icon-right-small"></i>
      </a>

      <div class="container">

        <div class="col-left">
          <div class="slick-wrapper">
            <span class="overlay"></span>
            <div class="slick-images">
              <div class="item bgimg js-mh" [style.background-image]="'url(' + location.image + ')'">
                <img alt="Locatie afbeelding" [src]="location.image"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-right js-mh">
          <div class="inner">
            <header class="school-header">
              <app-favorite-school [id]="location.id" [locationType]="'PO'"></app-favorite-school>
              <h1 class="h1 title">{{location.name}}</h1>
              <div class="meta">
                <ng-container *ngIf="location.education_field">
                  <span class="meta-text">{{location.education_field}}</span>
                  <span class="divider"> | </span>
                </ng-container>
                <span class="meta-text">Basisonderwijs</span>
              </div>
            </header>
            <div class="school-details">

              <div class="school-info">
                <div class="school-info-address">
                  <p>
                    {{location.street}} {{location.house_number}} {{location.house_number_addition}}<br>
                    {{location.zipcode}} {{location.place}}
                  </p>
                </div>
                <div class="school-info-brin">
                  <strong>Brin </strong><span class="brin-code">{{location.brin_id}}</span>
                </div>
              </div>

              <ul class="school-actions">
                <li><a [href]="location.website" *ngIf="location.website" target="_blank" class="button outline btn-a">Website</a></li>
                <li><a [href]="'tel:' + location.phone" class="button outline btn-b">{{location.phone}}</a></li>
                <li><a [href]="'mailto:' + location.email" class="button outline btn-c">Contact per mail</a></li>
                <li><a (click)="scroll(map, $event)" class="button outline btn-d">Weergeven op kaart</a></li>
              </ul>

            </div>

            <div class="school-attributes">
              <div class="attribute">
                <div class="key">Totaal aantal leerlingen</div>
                <div class="value">{{location.pupil_count}}</div>
              </div>
              <div class="attribute" *ngIf="location.signup_info">
                <div class="key">Leerlingplafond</div>
                <div class="value">

                  <a href="#" (click)="scroll(ceiling, $event)" *ngIf="location.signup_info.has_ceiling && location.signup_info.procedure_participation ?? true">
                    Ja
                    <i class="icon-right-small"></i>
                  </a>
                  <div *ngIf="!location.signup_info.has_ceiling && location.signup_info.procedure_participation ?? true">
                    Nee
                  </div>
                  <div *ngIf="location.signup_info.procedure_participation === false">
                    Geen deelname <span class="infotip" title="Deze school/locatie neemt niet deel aan de Haagse aanmeldprocedure"><i class="icon-info-circled-alt"></i></span>
                  </div>
                </div>
              </div>
              <div class="attribute" *ngIf="location.signup_info.max_seats">
                <div class="key">Max aantal plaatsen groep 1</div>
                <div class="value">{{location.signup_info.max_seats}}</div>
              </div>
              <div class="attribute" *ngIf="location.signup_info">
                <div class="key">Voorrangsregels</div>
                <div class="value">
                  <a href="#" (click)="scroll(precedenceRules, $event)"
                     *ngIf="location.signup_info.has_ceiling && location.signup_info.precedence_rule_1 !== null">
                    Ja
                    <i class="icon-right-small"></i>
                  </a>
                  <div *ngIf="!location.signup_info.has_ceiling || location.signup_info.precedence_rule_1 === null">
                    Nee
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-school-article type-a">
    <div class="st-inner">

      <div class="container">

        <div class="col-left indent c-article-list">

          <article class="article-item" *ngIf="location.about_us">
            <h2 class="article-title h2">Over onze school</h2>
            <div class="article-output">
              <p [innerHTML]="location.about_us"></p>
            </div>
          </article>

          <article class="article-item" *ngIf="location.our_education">
            <h2 class="article-title h2">Zo geven wij onderwijs</h2>
            <div class="article-output">
              <p [innerHTML]="location.our_education"></p>
            </div>
          </article>

        </div>

        <div class="col-right">
          <figure class="st-image bgimg has-before">
            <img alt="Foto van een basisschool" src="https://scholenwijzer.blob.core.windows.net/cdn/images/po/({{randInt}}).jpg"/>
          </figure>
        </div>

      </div>
    </div>
  </section>


  <section class="section section-school-text type-a">
    <div class="st-inner">
        <lottie-player
                src="/assets/img/svg/school/bo/5.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>
      <div class="container">
        <div class="row">

          <div class="col-md-4 col-lg-5"></div>

          <div class="col-md-7 col-lg-6 c-article-list">

            <article class="article-item" *ngIf="location.school_times">
              <h2 class="article-title h2">Schooltijden</h2>
              <div class="article-output">
                <p [innerHTML]="location.school_times"></p>
              </div>
            </article>
          </div>

        </div>
      </div>
    </div>
  </section>


  <section class="section section-school-article type-b" #ceiling>
    <div class="st-inner">
      <div class="container">

        <div class="col-small indent">
          <h2 class="h2">Kom kennismaken</h2>
          <div class="article-output">
            <p [innerHTML]="location.become_acquainted"></p>
          </div>
        </div>

        <div class="col-full indent" *ngIf="location.signup_info?.has_ceiling">
          <div class="c-article-data">

            <div class="article-top">
              <h2 class="h2">Leerlingplafond</h2>
            </div>

            <h3 class="h4 mb-2">Maximum aantal plaatsen in aanmeldjaar</h3>

            <div class="row article-tables">
              <app-po-year-capacity-table [yearCapacity]="location.last_year_capacity"
                                          [periods]="['18 dec t/m 12 jan', '15 mrt t/m 2 april', '10 jun t/m 1 juli', '16 t/m 30 sept']"
                                          additionalComment=""
                                          *ngIf="location.last_year_capacity">
              </app-po-year-capacity-table>

              <app-po-year-capacity-table [yearCapacity]="location.year_capacity" [signupInfo]="location.signup_info"
                                          [periods]="['16 dec t/m 10 jan', '17 mrt t/m 31 maart', '10 jun t/m 30 juni', '15 t/m 30 sept']"
                                          additionalComment=""
                                          *ngIf="location.year_capacity">
              </app-po-year-capacity-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="section section-school-text type-b">
    <div class="st-inner">
        <lottie-player
                src="/assets/img/svg/school/bo/5.json"
                renderer="svg"
                class="lottie-player"
                autoplay="true"
                loop="true">
        </lottie-player>
      <span class="after"></span>
      <div class="container">
        <div class="row">

          <div class="col-md-4 col-lg-5"></div>

          <div class="col-md-7 col-lg-6 c-article-list" #precedenceRules>

            <article class="article-item">
              <h2 class="article-title h2">Uw kind aanmelden</h2>
              <div class="article-output">
                <p>Aanmelden kan vanaf 3 jaar. U ontvangt van de gemeente Den Haag een kaart en een aanmeldformulier per
                  post als uw kind bijna 3 jaar is. Ieder kind in Den Haag heeft gelijke kansen. Daarom werken we met
                  een aanmeldprocedure. <a routerLink="/artikel/uw-kind-aanmelden-bij-de-basisschool">We leggen u graag uit hoe het werkt.</a></p>
                <p>Komt uw kind van een andere basisschool? Dan kunt u het beste contact met school opnemen om te
                  informeren naar de mogelijkheden.</p>
              </div>
            </article>

            <article class="article-item" *ngIf="precedenceRulesArray().length && location.signup_info.has_ceiling">
              <h2 class="article-title h2">Voorrangsregels</h2>
              <div class="article-output">
                <ol>
                  <li *ngFor="let rule of precedenceRulesArray()">
                    {{rule.rule}} <br>
                    {{rule.explanation}}
                  </li>
                </ol>
              </div>
            </article>

            <article class="article-item" *ngIf="location?.properties.length > 0">
              <h2 class="article-title h2">Bijzonderheden</h2>
              <ul class="c-tiplist">
                <li *ngFor="let property of location.properties">
                  - {{property.name}}
                </li>
              </ul>
            </article>

            <div class="article-item a-type-sppoh">
              <div class="article-actions floats">
                <div class="title h2 float-a">scholenopdekaart.nl</div>
                <a href="https://scholenopdekaart.nl" target="_blank" class="button float-b">Bekijk website <i
                        class="icon-right-small"></i></a>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="section section-school-map" #map>
    <div class="gmap" id="school_gmap"></div>
  </section>


  <section class="section section-school-articles">
    <div class="st-inner">
      <div class="container">

        <div class="st-header indent">
          <h2 class="h2 split-title">
            <span class="line-1 f1 mb-0">Alles over </span>
            <span class="line-2 h1">basisonderwijs in Den Haag</span>
          </h2>
        </div>

        <app-sub-category-list subCategorySlug="PO"></app-sub-category-list>

      </div>
    </div>
  </section>

</main>
