<main class="main-content debugx">
  <section class="section section-home-header">
    <div class="st-inner">
      <span class="before"></span>
      <div class="container">
        <div class="content">

          <div class="st-header">
            <h2 class="h2 f1 mb-0">Welkom op de</h2>
            <h3 class="h1">scholenwijzer Den Haag</h3>
          </div>

          <div class="category-nav">
            <ul class="category-list primary">
              <li><a [routerLink]="['/', 'categorie', 'ko']" class="cat-button ko" title="Ga naar kinderopvang">Kinderopvang <span class="arrow"><i class="icon-right-small"></i></span></a></li>
              <li><a [routerLink]="['/', 'categorie', 'po']" class="cat-button bo" title="Ga naar basisschool">Basisschool <span class="arrow"><i class="icon-right-small"></i></span></a></li>
              <li><a [routerLink]="['/', 'categorie', 'vo']" class="cat-button vo" title="Ga naar middelbare school">Middelbare school <span class="arrow"><i class="icon-right-small"></i></span></a></li>
              <li><a [routerLink]="['/', 'categorie', 'mbo']" class="cat-button mbo" title="Ga naar mbo">MBO <span class="arrow"><i class="icon-right-small"></i></span></a></li>
              <li><a [routerLink]="['/', 'categorie', 'hbo-wo']" class="cat-button hbo" title="Ga naar HBO &amp; WO">HBO &amp; Universiteit <span class="arrow"><i class="icon-right-small"></i></span></a></li>
            </ul>

            <ul class="category-list secondary">
              <li><a [routerLink]="['/', 'locatie', 'vavo', '2958']" class="cat-button vavo" title="Ga naar VAVO">VAVO <span class="arrow"><i class="icon-right-small"></i></span></a></li>
              <li><a [routerLink]="['/', 'categorie', 'so']" class="cat-button so" title="Ga naar speciaal onderwijs">Speciaal onderwijs <span class="arrow"><i class="icon-right-small"></i></span></a></li>
              <li><a [routerLink]="['/', 'categorie', 'int']" class="cat-button io" title="Ga naar internationaal onderwijs">Internationaal onderwijs <span class="arrow"><i class="icon-right-small"></i></span></a></li>
            </ul>
          </div>

        </div>
        <div class="content">
          <div class="newcomer-links">
            <div class="form newcomer-header-button">
              <h3 class="newcomer-header">Nieuw in Nederland? Meld je aan voor nieuwkomersonderwijs.</h3>
              <button class="newcomer-button" [routerLink]="['signup-newcomers', LanguageChoice.DUTCH]">
                aanmelden
                <img class="fat-arrow" src="assets/img/svg/home/arrow-right-white.svg">
              </button>
            </div>
            <ul class="newcomer-link-list">
              <li class="newcomer-link-item" [routerLink]="['signup-newcomers', LanguageChoice.ENGLISH]">
                <a class="newcomer-link">
                  <span>New in the Netherlands? Sign up for education!</span>
                  <img class="fat-arrow" src="assets/img/svg/home/arrow-right.svg">
                </a>
              </li>
              <li class="newcomer-link-item" [routerLink]="['signup-newcomers', LanguageChoice.UKRAINIAN]">
                <a class="newcomer-link">
                  <span>Новинка в Нiдерландах? Запишiться на навчанная.</span>
                  <img class="fat-arrow" src="assets/img/svg/home/arrow-right.svg">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="section section-home-article-a">
    <div class="st-inner py-0">
      <div class="container">
        <div class="row">

          <div class="col-md-5 col-left js-mh">
            <figure class="st-image bgimg" style="background-image: url('/assets/img/homepage/scholenwijzer01.jpg');">
              <span class="overlay"></span>
              <img src="/assets/img/homepage/scholenwijzer01.jpg" alt="Beschrijving van de foto"/>
            </figure>
          </div>

          <div class="col-md-7 col-right js-mh">
            <div class="inner">
              <div class="st-header">
                <h1 class="h1">Welkom op de Scholenwijzer</h1>
              </div>
              <div class="article-output">
                <p>In Den Haag zijn er duizenden kinderen die jaarlijks starten op een basisschool, doorstromen naar een middelbare school en daarna verder gaan studeren aan het mbo of hoger onderwijs. De Scholenwijzer geeft ouders en kinderen informatie over alle verschillende scholen in Den Haag en omstreken. Dat maakt het kiezen van een school een stuk makkelijker! En er is nog veel meer uit de Scholenwijzer te halen. Hoe weet u welke school het beste past bij u en uw kind? Waar moet u op letten? Hoe werkt het aanmelden? De Scholenwijzer geeft antwoord op al deze vragen en meer.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>


  <section class="section section-home-article-b">
    <div class="st-inner">
      <div class="container">
        <div class="row">

          <div class="col-md-6 col-lg-4 col-left">
            <figure class="st-image bgimg has-before" style="background-image: url('/assets/img/homepage/scholenwijzer-bredemeijer.jpg');">
              <img src="/assets/img/homepage/scholenwijzer-bredemeijer.jpg" alt="Beschrijving van de foto" />
            </figure>
          </div>

          <div class="col-md-6 col-lg-8 col-right">
            <div class="inner indent">
              <div class="st-header">
                <h2 class="h2 mb-0">Gelijke kansen voor ieder kind in Den Haag</h2>
              </div>
              <div class="article-output">
                <p>De gemeente Den Haag heeft met alle scholen en schoolbesturen samen de Scholenwijzer ontwikkeld om ouders en kinderen duidelijke informatie te geven over de verschillende onderwijsmogelijkheden in de regio Haaglanden. De gemeente Den Haag wil graag meer kansengelijkheid in het Haagse onderwijs. De doelstelling daarbij is dat ieder kind in Den Haag, ongeacht zijn of haar achtergrond, de kans krijgt om het beste uit zichzelf te halen.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>


  <section class="section section-home-article-c">
    <div class="st-inner pb-12">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-left">
            <div class="inner indent">
              <div class="st-header a-right">
                <h2 class="h2 mb-0">Leerroutekaart<br>Haaglanden</h2>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-right">
            <div class="inner">
              <div class="article-output">
                <p>De Leerroutekaart Haaglanden geeft duidelijk weer wat we allemaal aan onderwijs hebben: van het basisonderwijs tot en met voortgezet onderwijs, middelbaar en hoger beroepsonderwijs en speciaal onderwijs. Bij de Leerroutekaart draait het om twee vragen: Wat wil je later worden en welke route leg je dan af om te komen bij de opleiding die je graag wilt volgen? De Leerroutekaart is een poster in A4-vorm en <a href="/assets/attachments/2024_Leerroutekaart_september_digitaal.pdf" target="_blank" download>hier te downloaden</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-home-article-a">
    <div class="st-inner py-0">
      <div class="container">
        <div class="row">

          <div class="col-md-5 col-left js-mh">
            <figure class="st-image bgimg" style="background-image: url('/assets/img/homepage/scholenwijzer02.jpg');">
              <span class="overlay"></span>
              <img src="/assets/img/homepage/scholenwijzer02.jpg" alt="Beschrijving van de foto"/>
            </figure>
          </div>

          <div class="col-md-7 col-right js-mh">
            <div class="inner">
              <div class="st-header">
                <h2 class="h2">Samenwerking</h2>
              </div>
              <div class="article-output">
                <p>De Scholenwijzer is een samenwerkingsproject van de gemeente Den Haag met alle besturen van kinderopvangorganisaties, primair en voortgezet onderwijs, het mbo, hbo en wo, de verschillende HEA programma’s voor schoolkeuze (AanmeldenPO, BOVO Haaglanden en Spirit4You) en het samenwerkingsverband vo Passend Onderwijs Zuid-Holland West. Samen hebben zij hun best gedaan voor een volledige en juiste inhoud van deze website.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</main>
